import axios from "axios";
import store from "../redux/store/index";
import { getURL } from "../constants/index";

export async function getById(route, id) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null)
    puntToSignIn();
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: WebAPI_URL + route + "Get/" + id,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getByName(route, name) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null)
    puntToSignIn();
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: WebAPI_URL + route + name,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function get(route) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: WebAPI_URL + route + "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function update(route, event) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + route + "Update",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(event),
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function puntToSignIn() {
  const url = new URL(window.location.href);
  let relative = url.pathname + url.search + url.hash;
  if (relative.startsWith('/auth/sign-in'))
    relative = '';
  window.location.href = "/auth/sign-in?then=" +
    (relative ? encodeURIComponent(relative) : '');
}

export async function insert(route, event) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user.profile == null)
    puntToSignIn();
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + route + "Insert",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(event),
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function post(route, event) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null)
    puntToSignIn();
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + route + "Post",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(event),
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function remove(route, event) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null)
    puntToSignIn();
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + route + "Remove",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: JSON.stringify(event),
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
