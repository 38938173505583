/* eslint-disable */
import jwt_decode from "jwt-decode";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components/macro";
import store from "../src/redux/store";

import createTheme from "./theme";
import Routes from "./routes/Routes";

function App() {
  const theme = useSelector((state) => state.themeReducer);
  const history = useHistory();

  useEffect(() => {
    let hygeian = localStorage.getItem("Hygeian");
    if (hygeian != null) {
      const state = store.getState();
      try {
        state.authReducer.user = null;
        state.authReducer.user = JSON.parse(hygeian);
      } catch (err) {
        console.log('Failed to parse token in localStorage');
      }
      //var decoded = jwt_decode(state.authReducer.user.profile.token);
    }
  }, [history]);
  return (
    <React.Fragment>
      <Helmet titleTemplate="%s" defaultTitle="Hygeian Online" />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
